import React from "react"
import Layout from "antd/lib/layout/layout"
import SEO from "./seo"
import Header from "./header"
import Footer from "./footer"
import { JesRow, FullCol } from "./layout"

interface JesLayoutProps {
  title: string
  contentStyle?: React.CSSProperties
  footerStyle?: React.CSSProperties
  beforeContent?: React.ReactNode
}

export const JesLayout: React.FC<JesLayoutProps> = ({
  title,
  children,
  contentStyle,
  beforeContent,
  footerStyle = {},
}) => {
  return (
    <Layout
      style={{
        background: "white",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SEO title={title} />
      <Header></Header>
      {beforeContent}
      <JesRow style={{ padding: "90px 0", ...contentStyle }}>
        <FullCol>{children}</FullCol>
      </JesRow>
      <div style={{ flexGrow: 1 }} />
      <Footer style={footerStyle} />
    </Layout>
  )
}
