import React from "react"
import { Row, Col, RowProps, ColProps } from "antd"
import { useMediaQuerySafe } from "./use-mq-safe"
interface JesRowProps {
  fullWidth?: boolean
  noPadding?: boolean
  noGutter?: boolean
  rowStyle?: React.CSSProperties
  rowClassName?: string
}
export const JesVerticalSpacer = ({}) => {
  return <div style={{ width: "100%", background: "none", height: "30px" }} />
}

type JesColProps = ColProps & {
  noPaddingMobile?: boolean
  noPaddingOverride?: boolean
}
export const JesRow: React.FC<RowProps & JesRowProps> = ({
  children,
  align = "middle",
  style = {},
  fullWidth = false,
  noPadding = false,
  noGutter = false,
  rowStyle,
  rowClassName,
  ...props
}) => {
  return (
    <div
      style={{
        width: "100%",
        // padding: noPadding ? "0" : "0px 30px",
        ...style,
      }}
    >
      <Row
        gutter={
          (props.gutter && props.gutter) ||
          (noGutter
            ? [0, 0]
            : [
                { xs: 16, sm: 16, md: 32 },
                { xs: 16, sm: 16, md: 32 },
              ])
        }
        {...props}
        style={{
          maxWidth: fullWidth ? undefined : "1400px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: noPadding ? "0" : "0px 30px",
          ...rowStyle,
        }}
        className={rowClassName}
        align={align}
      >
        {children}
      </Row>
    </div>
  )
}

export const LargeCol: React.FC<JesColProps> = ({
  children = undefined,
  style = {},
  noPaddingMobile = false,
  ...props
}) => {
  const colSize = useMediaQuerySafe()
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    setIsMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])

  return (
    <Col
      xs={props.xs || 24}
      sm={props.sm || 24}
      md={props.md || 24}
      lg={props.lg || 16}
      style={{
        ...style,
        paddingLeft: noPaddingMobile && isMobile ? 0 : style.paddingLeft,
        paddingRight: noPaddingMobile && isMobile ? 0 : style.paddingRight,
      }}
    >
      {children}
    </Col>
  )
}
export const FillingCol: React.FC<JesColProps> = ({
  children = undefined,
  style = {},
  noPaddingMobile = false,
  className,
}) => {
  const colSize = useMediaQuerySafe()
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    setIsMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])
  return (
    <Col
      className={className}
      flex={"1"}
      style={{
        ...style,
        paddingLeft: noPaddingMobile && isMobile ? 0 : style.paddingLeft,
        paddingRight: noPaddingMobile && isMobile ? 0 : style.paddingRight,
      }}
    >
      {children}
    </Col>
  )
}
export const SmallCol: React.FC<JesColProps> = ({
  children = undefined,
  style = {},
  noPaddingMobile = false,
  ...props
}) => {
  const colSize = useMediaQuerySafe()
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    setIsMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])

  return (
    <Col
      xs={props.xs || 24}
      sm={props.sm || 24}
      md={props.md || 24}
      lg={props.lg || 8}
      style={{
        ...style,
        paddingLeft: noPaddingMobile && isMobile ? 0 : style.paddingLeft,
        paddingRight: noPaddingMobile && isMobile ? 0 : style.paddingRight,
      }}
    >
      {children}
    </Col>
  )
}
export const HalfCol: React.FC<JesColProps> = ({
  children = undefined,
  style = {},
  push = 0,
  noPaddingMobile = false,
  ...props
}) => {
  const colSize = useMediaQuerySafe()
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    setIsMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])
  return (
    <Col
      xs={props.xs || 24}
      sm={props.sm || 12}
      md={props.md || 12}
      lg={props.lg || 12}
      style={{
        ...style,
        paddingLeft: noPaddingMobile && isMobile ? 0 : style.paddingLeft,
        paddingRight: noPaddingMobile && isMobile ? 0 : style.paddingRight,
      }}
      push={push}
    >
      {children}
    </Col>
  )
}

export const QuarterCol: React.FC<JesColProps> = ({
  children = undefined,
  style = {},
  noPaddingMobile = false,
}) => {
  const colSize = useMediaQuerySafe()
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    setIsMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={6}
      style={{
        ...style,
        paddingLeft: noPaddingMobile && isMobile ? 0 : style.paddingLeft,
        paddingRight: noPaddingMobile && isMobile ? 0 : style.paddingRight,
      }}
    >
      {children}
    </Col>
  )
}

export const TierCol: React.FC<JesColProps> = ({
  children = undefined,
  noPaddingMobile = false,
  style = {},
}) => {
  const colSize = useMediaQuerySafe()
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    setIsMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])

  return (
    <Col
      xs={24}
      sm={24}
      md={8}
      style={{
        ...style,
        paddingLeft: noPaddingMobile && isMobile ? 0 : style.paddingLeft,
        paddingRight: noPaddingMobile && isMobile ? 0 : style.paddingRight,
      }}
    >
      {children}
    </Col>
  )
}

export const FullCol: React.FC<JesColProps> = ({
  children = undefined,
  style = {},
  noPaddingMobile = false,
  noPaddingOverride = false,
}) => {
  const colSize = useMediaQuerySafe()
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    setIsMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      style={{
        ...style,
        paddingLeft:
          noPaddingMobile && ["xs", "sm", "md"].includes(colSize)
            ? "auto"
            : "0",
        paddingRight:
          noPaddingMobile && ["xs", "sm", "md"].includes(colSize)
            ? "auto"
            : "0",
      }}
    >
      {children}
    </Col>
  )
}
