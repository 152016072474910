import React from "react"
import { notification, Tooltip } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { CopyOutlined } from "@ant-design/icons"

export const Iban = ({ inheritFontSize = true, bold = false }) => {
  const notifySuccess = () => {
    const args = {
      message: "Kopiert!",
      description: "IBAN erfolgreich kopiert. Danke!",
      duration: 3,
    }
    notification.success(args)
  }
  return (
    <CopyToClipboard
      text="DE49 7005 2060 0022 6765 14"
      onCopy={() => notifySuccess()}
    >
      <span style={{ fontSize: inheritFontSize ? "inherit" : "13px" }}>
        <span style={{ fontFamily: bold ? "Accord-Bold" : "inherit" , fontSize:"inherit"}}>
          DE49 7005 2060 0022 6765 14
        </span>{" "}
        <Tooltip title="IBAN KOPIEREN">
          <CopyOutlined
            size={1}
            style={{ marginLeft: "10px", cursor: "pointer" }}
          />
        </Tooltip>
      </span>
    </CopyToClipboard>
  )
}
