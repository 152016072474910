import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import { JesRow, SmallCol, LargeCol } from "./layout"
import "./header.css"
import { Affix } from "antd"
import { MenuOutlined, CloseOutlined } from "@ant-design/icons"
import { useMediaQuerySafe } from "../components/use-mq-safe"
import { NavigationLink, SmallNavigationLinkFake } from "./blocks"
import {
  useI18next,
  Trans,
  Link,
  I18nextContext,
} from "gatsby-plugin-react-i18next"

interface HeaderProps {}

const LangPicker = ({ withPadding = false, style = {} }) => {
  const { changeLanguage } = useI18next()
  const context = React.useContext(I18nextContext)
  return (
    <div
      className="header-link"
      style={{
        width: "100%",
        paddingTop: withPadding ? "20px" : 0,
        ...style,
      }}
    >
      <SmallNavigationLinkFake style={{ margin: 0, padding: 0 }}>
        <span
          style={{
            fontSize: "inherit",
            paddingRight: "5px",
            fontFamily: context.language === "de" ? "Accord-Bold" : "inherit",
            color: context.language === "de" ? "#001225" : "inherit",
          }}
          onClick={() => changeLanguage("de")}
        >
          D
        </span>{" "}
        |{" "}
        <span
          style={{
            fontSize: "inherit",
            paddingLeft: "5px",
            fontFamily: context.language === "en" ? "Accord-Bold" : "inherit",
            color: context.language === "en" ? "#001225" : "inherit",
          }}
          onClick={() => changeLanguage("en")}
        >
          EN
        </span>
      </SmallNavigationLinkFake>{" "}
    </div>
  )
}

export const Header: React.FC<HeaderProps> = () => {
  const [isAffixed, setAffixed] = React.useState(false)
  const data = useStaticQuery(
    graphql`
      query HeaderQuery {
        jesLogo: file(relativePath: { eq: "jes_logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const [isMobile, setisMobile] = React.useState(false)
  const [menuOpen, setMenuOpen] = React.useState(false)
  const { jesLogo } = data
  const colSize = useMediaQuerySafe()

  React.useEffect(() => {
    setisMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])
  const [opacity, setOpacity] = React.useState(0)
  React.useEffect(() => {
    setOpacity(isMobile && !menuOpen ? 0 : 100)
  }, [isMobile, menuOpen])
  return (
    <Affix offsetTop={0} onChange={affixed => setAffixed(affixed)}>
      <header
        style={{
          padding: isAffixed ? "30px 0 5px 0" : "30px 0px",
          background: isAffixed ? "rgb(239 233 228 / 92%)" : "#efe9e4",
          overflow: "hidden",
        }}
      >
        <JesRow align="stretch">
          <SmallCol sm={12} xs={12} md={12}>
            <Link to="/">
              <div
                className="logo-wrapper"
                style={{
                  maxWidth: "85%",
                  width: isAffixed ? "100px" : "200px",
                  // margin: isMobile && "auto",
                }}
              >
                <Img fluid={jesLogo.childImageSharp.fluid} />
              </div>
            </Link>
          </SmallCol>
          <LargeCol
            xs={12}
            sm={12}
            md={12}
            style={{
              textAlign: "right",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!isMobile && (
              <>
                <LangPicker
                  style={{
                    opacity: opacity,
                    paddingTop: !isAffixed,
                    paddingBottom: isAffixed ? "15px" : 0,
                  }}
                  withPadding
                />
                <div style={{ flexGrow: 1 }}></div>
              </>
            )}
            {isMobile && (
              <>
                {/* <div style={{ flexGrow: 1 }}></div> */}

                {menuOpen ? (
                  <CloseOutlined
                    style={{ textAlign: "right", fontSize: "25px" }}
                    onClick={() => setMenuOpen(false)}
                  />
                ) : (
                  <MenuOutlined
                    style={{ textAlign: "right", fontSize: "25px" }}
                    onClick={() => setMenuOpen(true)}
                  />
                )}
                <div style={{ flexGrow: 1 }}></div>
              </>
            )}
            <div
              className="header-links-wrapper"
              style={{
                paddingBottom: !isMobile || menuOpen ? "15px" : 0,
                paddingTop: isMobile && menuOpen ? "15px" : 0,
                opacity: opacity,
                visibility: isMobile && !menuOpen ? "hidden" : "visible",
                display: "flex",
                // flexWrap: "wrap",
                justifyContent: "flex-end",
                flexDirection: isMobile ? "column" : "row",
                transition: "max-height ease-in-out .5s, opacity linear .5s ",
                maxHeight: isMobile && !menuOpen ? 0 : 400,
                height: isMobile && !menuOpen ? 0 : "auto",
                textAlign: isMobile ? "right" : undefined,
              }}
            >
              <NavigationLink
                style={{
                  borderBottom: isMobile
                    ? "2px dotted rgba(117,107,39,0.16)"
                    : "none",
                }}
                to="/projekte/kuk"
              >
                <Trans>Projekte</Trans>
              </NavigationLink>
              <NavigationLink
                style={{
                  borderBottom: isMobile
                    ? "2px dotted rgba(117,107,39,0.16)"
                    : "none",
                }}
                to="/stiftung"
              >
                <Trans>Stiftung</Trans>
              </NavigationLink>
              <NavigationLink
                style={{
                  borderBottom: isMobile
                    ? "2px dotted rgba(117,107,39,0.16)"
                    : "none",
                }}
                to="/presse"
              >
                <Trans>Presse</Trans>
              </NavigationLink>
              <NavigationLink
                style={{
                  borderBottom: isMobile
                    ? "2px dotted rgba(117,107,39,0.16)"
                    : "none",
                }}
                to="/mitmachen"
              >
                <Trans>Mitmachen</Trans>
              </NavigationLink>
              <NavigationLink to="/kontakt">
                <Trans>Kontakt</Trans>
              </NavigationLink>
              {isMobile && <LangPicker withPadding />}
            </div>
          </LargeCol>
        </JesRow>
      </header>
    </Affix>
  )
}
export default Header
