import React from "react"
import { Link } from "gatsby-plugin-react-i18next"
import { useMediaQuerySafe } from "../components/use-mq-safe"

export const BLOCKS_SPACING = "40px"
export const BLOCKS_BACKGROUND = "white"
export const BLOCKS_SHADOW = "3px 3px 6px 2px rgba(0,0,0,0.3)"

export const BigCaption = ({ children, style = {} }) => {
  const colSize = useMediaQuerySafe()
  const mobileStyle = {
    fontSize: "30px",
    lineHeight: "34px",
    ...style,
  }
  const desktopStyle = {
    fontSize: "36px",
    lineHeight: "40px",
    ...style,
  }
  return (
    <div style={colSize === "xs" ? mobileStyle : desktopStyle}>
      „{children}“
    </div>
  )
}

export const LearnMoreLink = ({ to, text, style = {}, target = undefined }) => {
  const colSize = useMediaQuerySafe()
  const mobileStyle = {
    color: "#ff74a6",
    fontSize: "18px",
    lineHeight: "23px",
    fontFamily: "Accord-ExtraBold",
    ...style,
  }
  const desktopStyle = {
    color: "#ff74a6",
    fontSize: "18px",
    lineHeight: "23px",
    fontFamily: "Accord-ExtraBold",
    ...style,
  }
  return (
    <Link
      to={to}
      style={colSize === "xs" ? mobileStyle : desktopStyle}
      activeStyle={{ color: "#ff74a6" }}
      target={target}
    >
      {text}{" "}
      <span
        style={{
          fontFamily: "Accord",
          fontSize: "28px",
          position: "relative",
          top: "1px",
        }}
      >
        »
      </span>
    </Link>
  )
}

export const StandardParagraph = ({ children, style = {} }) => {
  const colSize = useMediaQuerySafe()
  const mobileStyle = {
    fontFamily: "Accord",
    fontSize: "21px",
    color: "#464646",
    ...style,
  }
  const desktopStyle = {
    fontFamily: "Accord",
    fontSize: "21px",
    color: "#464646",
    ...style,
  }
  return <p style={colSize === "xs" ? mobileStyle : desktopStyle}>{children}</p>
}

export const PrimaryHeading = ({ children, style = {} }) => {
  const colSize = useMediaQuerySafe()
  const mobileStyle = {
    fontSize: "30px",
    lineHeight: "34px",
    marginBottom: "20px",
    ...style,
  }
  const desktopStyle = {
    fontSize: "36px",
    lineHeight: "40px",
    marginBottom: "20px",
    ...style,
  }
  return (
    <div style={colSize === "xs" ? mobileStyle : desktopStyle}>{children}</div>
  )
}
export const SecondaryHeading = ({ children, style = {} }) => {
  const colSize = useMediaQuerySafe()
  const mobileStyle = {
    fontFamily: "Accord-Bold",
    // fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    ...style,
  }
  const desktopStyle = {
    fontFamily: "Accord-Bold",
    // fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    ...style,
  }
  return (
    <div style={colSize === "xs" ? mobileStyle : desktopStyle}>{children}</div>
  )
}

export const Legend = ({ children, style = {} }) => {
  const colSize = useMediaQuerySafe()
  const [mobileStyle, setMobileStyle] = React.useState({
    fontSize: "18px",
    color: "#464646",
    lineHeight: "23px",
  })
  const [desktopStyle, setDesktopStyle] = React.useState({
    fontSize: "18px",
    color: "#464646",
    lineHeight: "23px",
  })
  return (
    <div
      style={{ ...(colSize === "xs" ? mobileStyle : desktopStyle), ...style }}
    >
      {children}
    </div>
  )
}

export const NavigationLink = ({
  to = null,
  children,
  noMargin = false,
  style = {},
  mobileBreakPoints = ["xs", "sm", "md"],
}) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const colSize = useMediaQuerySafe()

  React.useEffect(() => {
    setIsMobile(mobileBreakPoints.includes(colSize))
  }, [colSize])
  return (
    <Link
      className="header-link"
      partiallyActive
      style={{
        fontSize: "24px",
        marginLeft: isMobile || noMargin ? 0 : "10px",
        marginRight: isMobile || noMargin ? 0 : "10px",
        textTransform: "uppercase",
        color: "#a5998f",
        fontFamily: "Accord-ExtraBold",
        ...style,
      }}
      to={to}
      activeStyle={{ color: "#001225" }}
      activeClassName="header-link-active"
    >
      {children}
    </Link>
  )
}
export const NavigationLinkWithoutLink = ({
  to = null,
  children,
  noMargin = false,
  style = {},
  mobileBreakPoints = ["xs", "sm", "md"],
}) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const colSize = useMediaQuerySafe()

  React.useEffect(() => {
    setIsMobile(mobileBreakPoints.includes(colSize))
  }, [colSize])
  return (
    <div
      style={{
        fontSize: "24px",
        marginLeft: isMobile || noMargin ? 0 : "10px",
        marginRight: isMobile || noMargin ? 0 : "10px",
        textTransform: "uppercase",
        color: "#a5998f",
        fontFamily: "Accord-ExtraBold",
        cursor: "pointer",
        ...style,
      }}
    >
      {children}
    </div>
  )
}
export const SmallNavigationLinkFake = ({
  onClick = () => {},
  children,
  noMargin = false,
  style = {},
}) => {
  return (
    <div
      onClick={onClick}
      className="header-link"
      style={{
        fontSize: "18px",
        margin: noMargin ? 0 : "0px 10px",
        textTransform: "uppercase",
        color: "#a5998f",
        fontFamily: "Accord-ExtraBold",
        cursor: "pointer",
        display: "inline-flex",
        paddingRight: "10px",
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export const SmallNavigationLink = ({
  children,
  to,
  noMargin = false,
  style = {},
}) => {
  return (
    <Link
      to={to}
      className="header-link"
      style={{
        fontSize: "18px",
        margin: noMargin ? 0 : "0px 10px",
        textTransform: "uppercase",
        color: "#a5998f",
        fontFamily: "Accord-ExtraBold",
        cursor: "pointer",
        display: "inline-flex",
        paddingRight: "10px",
        ...style,
      }}
    >
      {children}
    </Link>
  )
}

export const Bold = ({ children }) => {
  return (
    <span style={{ fontFamily: "Accord-Bold", fontSize: "inherit" }}>
      {children}
    </span>
  )
}
